const baseURL = {
    state: {
        baseURL: 'https://www.jidcon.com/api/',
        // baseURL: 'http://192.168.0.193:3371/',
        downloadURL: 'https://www.jidcon.com/download/'
    },
    getters: {

    },


}
export default baseURL
