module.exports = {
  common: {
    confirm: "确认",
    cancel: "取消",
    deleteSuccess: "删除成功",
    deleteFailed: "删除失败",
    copySuccess: "复制成功",
  },
  header: {
    mainProducts: "主要产品",
    technicalProposal: "技术方案",
    information: "资讯",
    companyProfile: "公司简介",
    aboutUs: "关于我们",
    honor: "荣誉资质",
    sampleApply: "样品申请",
    productManual: "济德产品手册",
    jidc: "济德精密",
    personalCenter: "个人中心",
    logout: "退出登录",
    clickLogin: "点击登录",
    weChatLogin: "微信扫一扫可免注册登录",
    loginToApplySamples: "登录即享免费样品申请",
    loginFirst: "请先登录！",
    quit: "退出",
    loginTitle: "邮箱登录",
    regTitle: "注册账户",
    resetTitle: "重置密码",
    login: "登录",
    toLogin: "去登录",
    reset: "重置密码",
    signUp: "注册",
    submit: "提交",
    tips: "提示",
    email: "请输入邮箱",
    pwd: "请输入密码",
    confirmPwd: "请确认密码",
    verCode: "请输入验证码",
    sendCode: "发送验证码",
    loginAgree: "登录即代表阅读并同意",
    signUpAgree: "注册即代表阅读并同意",
    submitAgree: "提交即代表阅读并同意",
    protocol: "《服务协议和隐私政策》",
    regSuccess: "恭喜，您已注册成功！",
    loginSuccess: "登录成功，欢迎您！",
    resetSuccess: "修改成功",
    jmp2Login: "即将跳转到登录界面...",
    pwdRule: "至少8位，必须同时包含字母、数字",


    technicalProposalList: {
      solutionList: "解决方案列表",
      carRegion: "汽车领域",
      newEnergyConnector: "新能源连接器",
      heavyDutyConnector: "重载连接器（非道路）",
      houseAppliances: "家电",
      greenEnergy: "光伏、绿色能源",
    },

    productList: {
      housing: "塑壳",
      terminals: "压接端子",
      connectors: "连接器",
      sealingProducts: "防水件",
      customizedProducts: "定制产品",
    },

    searchPlaceholder: "搜索济德/同等规格型号"
  },
  footer: {
    aboutUs: {
      self: "关于我们",
      "address-line1": "广东济德精密电子有限公司",
      "address-line2": "广东省东莞市大朗镇德利路1号",
    },
    technicalProposal: {
      self: "技术方案",
      carRegion: "汽车领域",
      newEnergyConnector: "新能源连接器",
      heavyDutyConnector: "重载连接器（非道路）",
      houseAppliances: "家电",
      greenEnergy: "光伏、绿色能源",
    },
    contactUs: {
      self: "联系我们",
      serviceHotline: "24小时服务热线"
    },
    followUs: {
      self: "关注",
      officialAccount: "济德精密公众号",
      applets: "济德精密小程序",
    },
    allRightsReserved: "版权所有"
  },
  // 首页
  index: {
    mission: "让连接更可靠 更简单 更环保",
    profileDetails: "济德精密始终坚持“以客户为中心”的理念，持续加大科技投入，培养人才队伍，提升企业核心竞争力，为客户提供连接器领域全产业链服务。",
    showMore: "查看更多",
    // 动态
    dynamics: {

    },
    // 解决方案
    solutions: {

    }
  },
  // 技术方案
  technicalProposal: {

  },
  // 资讯
  information: {
    infoTitle: "济德资讯中心",
    infoListEmpty: "资讯列表为空",
    searchPlaceholder: "请输入内容",
    releaseTime: "发布时间",
    readNum: "阅读量",
  },
  // 公司简介
  companyProfile: {
    companyProfile: "公司简介",
    profileDetails: "济德精密是一家集产品设计、模具开发、精密冲压、注塑、加工和自动化装配为一体，并拥有多种自主开发金属铆合生产工艺的连接器精密零部件一级生产商。济德精密东莞、青岛、德州生产中心均配合配备经验丰富的高级工程研发人员、训练有素的品保工程师团队以及各种精密设备，济德精密目前拥有北京、上海、深圳、青岛、德州、济南等多地办事处，业务覆盖全国，可以帮助全国范围内客户完成多种项目和终端产品的设计生产需求，产品主要应用于交通、新能源、家电、医疗、工业等各个领域。",
    superiority: "我们的优势",
    honor: "荣誉资质",
    ourLayout: "我们的布局",
    honorText1: "荣誉资质",
    honorText2: "济德拥有丰富经验和资质品质保证",
    honorText3: "品质保证",
  },
  // 样品申请
  sampleApply: {
    accountInfo: {
      self: "账户信息",
      baseInfo: "基本信息",
      username: "用户名",
      receiveAddress: "收货地址",
      addressList: "收货地址列表",
      addAddress: "新增收货地址",
      editAddress: "编辑收货地址",
      noAddress: "暂无收货地址",
      defaultAddress: "默认地址",
      asDefaultAddress: "设为默认地址",
      receiver: "收货人",
      telephone: "联系电话",
      phone: "手机号",
      area: "所在地区",
      address: "详细地址",
      postcode: "邮 编",
      email: "邮 箱",
      textLengthLimit50: "最多50字",
      receiverTips: "请填写收货人",
      telephoneTips: "请填写联系方式",
      validPhoneTips: "请输入合法的手机号",
      addressTips: "请填写详细地址",
      setDefaultAddressSuccess: "设置默认收货地址成功！",
      deleteAddressTips: "是否要删除当前收货地址?",
      addressDeleteSuccess: "地址删除成功！",
      addressAddSuccess: "新增收货地址成功！",
      addressEditSuccess: "更新收货地址成功！",
    },
    sampleApply: {
      self: "样品申请",
      availableNum: "当前可申请样品总数",
      partNo: "产品型号",
      category: "产品类别",
      operation: "操作",
      searchPlaceholder: "搜索济德型号/同等规格型号",
      validQuantityTips: "请输入合适的数量！",
      toCartSuccess: "加入购物车成功",
      cartIsFull: "购物车已满",
      toCartFailed: "加入购物车失败",
    },
    cart: {
      self: "我的购物车",
      partNo: "产品型号",
      category: "产品类别",
      quantity: "数量",
      operation: "操作",
      submit: "提交申请",
      requestCartFailed: "请求购物车接口失败！",
      updateFailed: "修改失败了",
      selectGoodsTips: "请选择商品！",
    },
    order: {
      self: "样品订单",
      partNo: "商品型号",
      partNoPlaceholder: "请输入商品型号",
      orderNo: "订单编号",
      orderNoPlaceholder: "请输入订单编号",
      search: "查询",
      item: "序号",
      orderTime: "下单时间",
      orderStatus: "订单状态",
      expressNo: "快递单号",
      receiveInfo: "收货信息",
      operation: "操作",
      orderStatusList: {
        waitDeal: "待处理",
        dealing: "处理中",
        hasSend: "已发货",
        canceled: "已取消",
      },
      cancelOrder: "取消订单",
      cancelReason: "取消原因",
      textLengthLimit30: "最多30字",
      enterCancelReasonTips: "请输入取消原因！",
      reEnterCancelReasonTips: "取消原因最多30字，请重新输入！",
      orderCancelSuccess: "取消样品申请成功！",
    },
    preOrder: {
      sampleList: "商品清单",
      partNo: "产品型号",
      category: "产品类别",
      quantity: "数量",
      projectInfo: "项目信息",
      directCustomer: "直接客户：",
      directCustomerPlaceholder: "请输入贵公司名称",
      mainFactory: "主 机 厂：",
      projectName: "项目名称：",
      functionalPart: "功能部位：",
      annualConsumption: "年 用 量：",
      productionTime: "投产时间：",
      closingTime: "结案时间：",
      remarks: "订单备注：",
      receiveInfo: "收货人信息",
      addressPlaceholder: "请选择",
      editAddress: "编辑",
      newAddress: "新增收货地址",
      submit: "提交申请",
      productionTimeTips: "请选择投产日期",
      closingTimeTips: "请选择结案日期",
      remarkTips: "请输入订单备注",
      directCustomerTips: "请填写直接客户",
      functionalPartTips: "请填写功能部位",
      annualConsumptionTips: "请填写年用量",
      productionTimeTips1: "请选择投产时间",
    },
    orderDetail: {
      orderDetailTitle: "样品申请-详情页",
      baseInfo: "基本信息",
      jidcPartNo: "济德料号",
      noExpressInfo: "暂无快递信息",
      showCancelReason: "查看取消原因",
    },
  },
  // 解决方案
  solutions: {
    solutionCenter: "济德解决方案中心",
    solutionListEmpty: "解决方案列表为空",
  },
  paramSelect: {
    currentCategory: "当前分类",
    reset: "重置",
    screen: "筛选",
    noMatchedData: "无匹配数据",
    noData: "无数据",
  },
  product: {
    jidcPartNo: "济德型号",
    desc: "描述",
    category: "产品类别",
    emptyResult: "搜索结果为空",
    prodDoc: "产品资料",
    sampAvl: "可申请样品",
    addToCart: "添加至样品购物车",
    productIntroduction: "产品简介",
    detailedParams: "详细参数",
  }
}
